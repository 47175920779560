<template>
  <div>
    <div class="router-header flex flex-wrap items-center mb-6">
      <div
        class="content-area__heading pr-4 border-0 md:border-r border-solid border-grey-light"
      >
        <h2 class="mb-1">View Admin</h2>
      </div>
      <div class="vx-breadcrumb ml-4 md:block hidden">
        <ul class="flex flex-wrap items-center">
          <li class="inline-flex items-end">
            <a
              @click="$router.push({ path: '/admin' })"
              class="router-link-active"
              ><span class="feather-icon select-none relative"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-home h-5 w-5 mb-1 stroke-current text-primary"
                >
                  <path
                    d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"
                  ></path>
                  <polyline
                    points="9 22 9 12 15 12 15 22"
                  ></polyline></svg></span></a
            ><span class="breadcrumb-separator mx-2"
              ><span class="feather-icon select-none relative"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right w-4 h-4"
                >
                  <polyline points="13 17 18 12 13 7"></polyline>
                  <polyline points="6 17 11 12 6 7"></polyline></svg></span
            ></span>
          </li>
          <li class="inline-flex items-center">
            <a
              @click="$router.push({ name: 'learning-centre-list' })"
              class="router-link-active"
              >Learning Centres</a
            ><span class="breadcrumb-separator mx-2 flex items-start"
              ><span class="feather-icon select-none relative"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right w-4 h-4"
                >
                  <polyline points="13 17 18 12 13 7"></polyline>
                  <polyline points="6 17 11 12 6 7"></polyline></svg></span
            ></span>
          </li>
          <li class="inline-flex items-center">
            <a
              @click="
                $router.push({
                  name: 'learning-centre-view',
                  params: { id: $route.params.centerId }
                })
              "
              class="router-link-active"
              >Learning Centre Detail</a
            ><span class="breadcrumb-separator mx-2 flex items-start"
              ><span class="feather-icon select-none relative"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right w-4 h-4"
                >
                  <polyline points="13 17 18 12 13 7"></polyline>
                  <polyline points="6 17 11 12 6 7"></polyline></svg></span
            ></span>
          </li>
          <!---->
          <li class="inline-flex">
            <span class="cursor-default" style="text-transform: capitalize;">
              Admin Details</span
            >
          </li>
        </ul>
      </div>
    </div>
    <vx-card class="mb-base" v-if="editAdmin === false">
      <div class="flex justify-between items-center mb-5">
        <h2 class="font-bold" style="color: black">Admin Details</h2>
        <vs-button class="mr-4" @click="editAdmin = true">Edit</vs-button>
      </div>

      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
        class="admin-detail-wrap"
      >
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full p-5">
            <label class="font-bold">Name:</label>
            <p>{{ adminDetails.fullName }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full p-5">
            <label class="font-bold">Centre:</label>
            <p>{{ adminDetails.companyName | capitalize }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full p-5">
            <label class="font-bold">Street Address:</label>
              <p v-if="adminDetails.address">{{ adminDetails.address.state }}</p>
              <p v-else>n/a</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full p-5">
            <label class="font-bold">Phone Number:</label>
            <p>{{ adminDetails.mobileNumber }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full p-5">
            <label class="font-bold">Username:</label>
            <p>{{ adminDetails.email }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full p-5">
            <label class="font-bold">Country:</label>
              <p v-if="adminDetails.country">{{ adminDetails.country.name }}</p>
              <p v-else>n/a</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full p-5">
            <label class="font-bold">Account Status:</label>
            <p>
              {{
                adminDetails.accountStatus === "Active" ? "Enabled" : "Disabled"
              }}
            </p>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
    <vx-card class="mb-base" v-if="editAdmin === true">
      <h2 class="font-bold mb-5" style="color: black">Edit Admin Details</h2>
      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        class="w-full lg:w-3/4"
      >
        <vs-col
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          class="w-full lg:w-1/2 mt-5 lg:pr-5"
        >
          <div class="w-full">
            <vs-input
              :success="
                !errors.first('fullName') && adminDetails.fullName != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('fullName') ? true : false"
              v-validate="'required|alpha_spaces|max:150'"
              name="fullName"
              data-vv-as="Full Name"
              label="Full Name"
              icon-no-border
              icon="icon icon-user"
              icon-pack="feather"
              class="w-full"
              v-model="adminDetails.fullName"
            />
            <span class="text-danger text-sm">{{
              errors.first("fullName")
            }}</span>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          class="w-full lg:w-1/2 mt-5 lg:pl-5"
        >
          <div class="w-full">
            <vs-input
              :success="!errors.first('email') && adminDetails.email != ''"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('email') ? true : false"
              v-validate="'required|email'"
              name="email"
              data-vv-as="Email"
              label="Username"
              icon-no-border
              icon="icon icon-mail"
              icon-pack="feather"
              class="w-full"
              v-model="adminDetails.email"
            />
            <span class="text-danger text-sm">{{ errors.first("email") }}</span>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          class="w-full lg:w-1/2 mt-5 lg:pr-5"
        >
          <div class="w-full">
            <vs-input
              :success="
                !errors.first('mobileNumber') && adminDetails.mobileNumber != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('mobileNumber') ? true : false"
              v-validate="'required|numeric'"
              name="mobileNumber"
              data-vv-as="Contact Number"
              label="Contact Number"
              icon-no-border
              icon="icon icon-phone"
              icon-pack="feather"
              class="w-full"
              v-model="adminDetails.mobileNumber"
            />
            <span class="text-danger text-sm">{{
              errors.first("mobileNumber")
            }}</span>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          class="w-full lg:w-1/2 mt-5 lg:pl-5"
        >
          <div class="w-full">
            <label class="block mb-2">Country</label>
            <v-select
              :options="countryOptions"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="countryFilter"
              class="mb-4 md:mb-0"
            />
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          class="w-full lg:w-1/2 mt-5 lg:pr-5"
        >
          <div class="w-full">
            <label class="block mb-2">State</label>
            <v-select
              :options="stateOptions"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="stateFilter"
              class="mb-4 md:mb-0"
            />
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          class="w-full lg:w-1/2 mt-5 lg:pl-5"
        >
          <div class="w-full">
            <label class="block mb-2">Account Status</label>
            <vs-switch v-model="accountStatus" />
          </div>
        </vs-col>
      </vs-row>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-w="12">
          <div class="w-full m-5 ml-0">
            <vs-button @click="editHandler" :disabled="!isFormValid"
              >Submit</vs-button
            >
            <vs-button
              @click="editAdmin = false"
              class="ml-4 vs-button-secondary"
              >Cancel</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import _ from "lodash";

export default {
  data() {
    return {
      adminDetails: {},
      editAdmin: false,
      adminId: this.$route.params.id,
      countryOptions: [],
      stateOptions: [],
      countryFilter: { label: "All", value: "all" },
      stateFilter: { label: "All", value: "all" },
      centerId: this.$route.params.center,
      accountStatus: false
    };
  },
  components: {
    vSelect
  },
  computed: {
    isFormValid() {
      return (
        !this.errors.any() &&
        this.adminDetails.fullName &&
        this.adminDetails.mobileNumber &&
        this.adminDetails.email &&
        this.countryFilter.value != "all" &&
        this.stateFilter.value != "all"
      );
    }
  },
  methods: {
    ...mapActions("center", [
      "getAdminDetails",
      "getAllCountries",
      "editAdminDetails"
    ]),
    getDetails(id) {
      this.$vs.loading();
      this.getAdminDetails(id).then(res => {
        this.adminDetails = res.data.data;
        this.accountStatus =
          this.adminDetails.accountStatus == "Active" ? true : false;
        this.$vs.loading.close();
        this.getAllCountries().then(res => {
          this.countryOptions = this.$store.state.center.countryOptions;
          let filter = {
            label: this.adminDetails.country.name,
            value: this.adminDetails.country.name
          };
          let selectedCountry = _.find(this.countryOptions, filter);
          this.countryFilter = selectedCountry;
          this.stateOptions = this.countryFilter.states;
          this.stateFilter = {
            label: this.adminDetails.address.state,
            value: this.adminDetails.address.state
          };
        });
      });
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    async editHandler() {
      await this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading();
          this.adminDetails.address = this.adminDetails.address || {}
          this.adminDetails.address.state = this.stateFilter.value;
          this.adminDetails.accountStatus =
            this.accountStatus == true ? "Active" : "Inactive";
          let info = {
            adminId: this.adminDetails._id,
            details: this.adminDetails
          };
          this.editAdminDetails(info).then(res => {
            this.$vs.loading.close();
            this.showMessage(
              "Success",
              "Centre admin edited successfully.",
              "success"
            );
          });
          this.editAdmin = false;
          this.getDetails(this.adminDetails._id);
        }
      });
    }
  },
  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
    }
  },
  created() {
    let id = this.$route.params.id;
    this.getDetails(id);
  }
};
</script>
<style scoped lang="scss">
.admin-detail-wrap {
  border: 1px solid #d7d8d8;
  .w-full {
    label {
      position: relative;
      margin-right: 20px;
      &:after {
        content: ":";
        font-weight: 700;
        position: absolute;
        top: -1px;
        right: 0;
      }
    }
    @media (min-width: 768px) {
      display: flex;
      label {
        width: 250px;
      }
    }
  }
}
</style>
